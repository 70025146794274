import React from 'react';
import UploadIcon from '../icons/upload.svg';
import PropTypes from 'prop-types';

const UploadBox = ({ handleClick, className, style }) => {
  return (
    <div className={`flex flex-row items-center justify-between ${className || ''}`} style={style} onClick={handleClick}>
      <input placeholder='Upload a banner' readOnly className='cursor-pointer' />
      <img src={UploadIcon} alt="Upload Icon" className="upload-icon w-[20px] h-[20px]" />
    </div>
  );
};

UploadBox.propTypes = {
  handleClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

UploadBox.defaultProps = {
  className: '',
  style: {},
};

export default UploadBox;