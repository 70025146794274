import { createPublicClient, http } from 'viem'
import { mainnet, polygon, base, sepolia, bsc } from 'viem/chains'
import Web3 from 'web3'

export const publicClient = createPublicClient({
    chain: bsc,
    transport: http()
})

const isProduct = 'local'

const PROVIDER_URL_BSC = 'https://binance.llamarpc.com'
const PROVIDER_URL_SEP = 'https://ethereum-sepolia.blockpi.network/v1/rpc/public'
const PROVIDER_URL_BASE = 'https://mainnet.base.org'
const PROVIDER_URL_POL = 'https://polygon.llamarpc.com'
const PROVIDER_URL_ETH = 'https://ethereum-rpc.publicnode.com'
const PROVIDER_URL_TAO = 'https://rpc.taoevm.io'

export const web3ClientBsc = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_BSC))
export const web3ClientSep = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_SEP))
export const web3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_ETH))
export const baseWeb3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_BASE))
export const polWeb3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_POL))
export const taoWeb3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_TAO))

export const web3Clients = {
    56: web3ClientBsc,
    11155111: web3ClientSep,
    1: web3Client,
    137: polWeb3Client,
    8453: baseWeb3Client,
    10321: taoWeb3Client
}

export const imageUrl = isProduct !== 'local' ? 'http://localhost:8000/api/uploads/' : 'https://api.hotcurves.fun/api/uploads/'

export const apiUrl = isProduct !== 'local' ? 'http://localhost:8000' : 'https://api.hotcurves.fun'

export const imageUploadUrl = isProduct !== 'local' ? 'http://localhost:8000/' : 'https://api.hotcurves.fun/'

export const ethPriceApiUrl = {
 1: 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',
 56: 'https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD',
 11155111: 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',
 8453: 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',
 137: 'https://min-api.cryptocompare.com/data/price?fsym=POL&tsyms=USD',
 10321: 'https://min-api.cryptocompare.com/data/price?fsym=TAO&tsyms=USD'
}

// export const supportedChainIds = [mainnet.id, bsc.id, base.id, 10321]
export const supportedChainIds = [10321]

export const chainLogos = {
    1: '/eth.svg',
    56: '/bsc.svg',
    11155111: '/eth.svg',
    137: '/polygon.svg',
    8453: '/base.svg',
    10321: '/tao.png'
}

export const feeAmounts = {
    1: 0.0012,
    8453: 0.0012,
    56: 0.005,
    137: 7.13,
    11155111: 0.0012,
    10321: 0.0012
}

export const initialEth = {
    1: 1.9,
    8453: 1.9,
    56: 8.48,
    137: 12558,
    11155111: 1.9,
    10321: 1.9
}

export const coinNames = {
    1: 'ETH',
    8453: 'ETH',
    56: 'BNB',
    11155111: 'ETH',
    10: 'ETH',
    137: 'POL',
    10321: 'TAO'
}

export const featureAmounts = {
    56: [0, 0.5, 0.75, 1],
    1: [0, 0.12, 0.18, 0.24],
    8453: [0, 0.12, 0.18, 0.24],
    137: [0, 713, 1070, 1426],
    11155111: [0, 0.12, 0.18, 0.24],
    10321: [0, 0.12, 0.18, 0.24],
}

export const scanLinks = {
    8453: 'https://basescan.org/',
    1: 'https://etherscan.io/',
    56: 'https://bscscan.com/',
    11155111: 'https://sepolia.etherscan.io/',
    137: 'https://polygonscan.com/',
    10: 'https://optimistic.etherscan.io/',
    10321: 'https://taoscan.org/',
}

export const scanApiLinks = {
    8453: 'https://api.basescan.org/api',
    1: 'https://api.etherscan.io/api',
    56: 'https://api.bscscan.com/api',
    11155111: 'https://api-sepolia.etherscan.io/api',
    137: 'https://api.polygonscan.com/api',
    10321: 'https://taoscan.org/api'
}

export const apiKeys = {
    56: 'Y7TXAF2H8KCPY7AXFT7DSTBFUVH794ZCST',
    1: '2E446YFS6EPUCJQ84W6GHX626759K121NP',
    11155111: '2E446YFS6EPUCJQ84W6GHX626759K121NP',
    8453: '26VQ3BGAUVDCN9VCNP7PZNG47I8X3S82F2'
}

export const chainNames = {
    1: 'eth',
    11155111: 'eth',
    8453: 'base',
    56: 'bnb',
    137: 'polygon',
    10321: 'tao'
}

export const chainNames1 = {
    1: 'ethereum',
    11155111: 'ethereum',
    8453: 'base',
    56: 'bsc',
    137: 'polygon',
    10321: 'tao'
}

export default function formatNumber(number) {
    if (number >= 1000000) {
        return (number / 1000000).toLocaleString() + 'M';
    } else if (number >= 1000) {
        return (number / 1000).toLocaleString() + 'K';
    } else {
        return number.toString();
    }
}