/* eslint-disable no-useless-concat */
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import '../App.css'
import ChadFactoryAbi from '../config/ChadFactoryAbi.json'
import ERC20Abi from '../config/TokenAbi.json'
import '../styles/MainContainer.css'
import Input from '../components/Input.tsx'
import TextArea from '../components/TextArea.tsx'
import { writeContract, readContract } from '@wagmi/core'
import ClipLoader from 'react-spinners/ClipLoader'
import { waitForTransaction } from '@wagmi/core'
import { useWeb3Modal } from '@web3modal/react'
import { toast } from 'react-hot-toast'
import Footer from '../components/Footer.jsx'
import 'react-datepicker/dist/react-datepicker.css'
import TopBar from '../components/TopBar.jsx'
import LogoUploadBox from '../components/LogoUploadBox.jsx'
import BannerUploadBox from '../components/BannerUploadBox.jsx'
import { web3Clients, imageUploadUrl, ethPriceApiUrl, chainLogos, feeAmounts, initialEth, featureAmounts, coinNames, supportedChainIds, chainNames1 } from '../utils/constants.ts'
import { getFactoryAddress, getRouterAddress } from '../utils/addressHelpers.ts'
import { Tooltip } from 'react-tooltip';
import sampleBanner from '../icons/sampleBanner.svg'
import web from '../icons/website.svg'
import x from '../icons/x-icon.svg'
import tlg from '../icons/telegram.svg'
import sampleLogo from '../icons/sampleLogo.webp'


const App = () => {
  const { chain } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()
  const [logoPreview, setLogoPreview] = useState<string | null>(null)
  const [logoFile, setLogoFile] = useState<File | null>(null)
  const [bannerPreview, setBannerPreview] = useState<string | null>(null)
  const [bannerFile, setBannerFile] = useState<File | null>(null)
  const logoFileInput = useRef<HTMLInputElement>(null)
  const bannerFileInput = useRef<HTMLInputElement>(null)
  const { isConnected, address } = useAccount()
  const [maxWallet, setMaxWallet] = useState(1)
  const [featureOption, setFeatureOption] = useState(0)
  const [chainId, setChainId] = useState(supportedChainIds.includes(chain?.id) ? chain.id : 10321)
  const [routers, setRouters] = useState(getRouterAddress(chainId))
  const [routerAddr, setRouter] = useState(Object.values(routers)[0])
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenDescription, setTokenDescription] = useState('')
  let [loading, setLoading] = useState(false)
  let [creating, setCreating] = useState(false)
  const [website, setWebsite] = useState('')
  const [telegram, setTelegram] = useState('')
  const [discord, setDiscord] = useState('')
  const [twitter, setTwitter] = useState('')
  const [firstConnect, setFirstConnect] = useState(false)
  const { open } = useWeb3Modal()
  const [depositAmount, setDepositAmount] = useState('')
  const [accountBalance, setAccountBalance] = useState(0)


  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  useEffect(() => {
    const fetchBalance = async () => {
      if (address) {
        let accountBalance = await web3Clients[chainId].eth.getBalance(address)
        accountBalance = web3Clients[chainId].utils.fromWei(accountBalance, 'ether')
        setAccountBalance(accountBalance)
      }
    }
    if (address) {
      fetchBalance()
    }
  }, [])

  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  const onBlackPumpCreate = async () => {
    try {
      setCreating(true)
      let feeAmount = feeAmounts[chainId];
      let featuredAmount = featureAmounts[chainId][featureOption]

      if (Number(depositAmount) > 0) {
        let newEthAmount = Number(initialEth[chainId]) + Number(depositAmount)
        let newTokenAmount = 1073 * 10 ** 6 * Number(initialEth[chainId]) / newEthAmount
        let tokenAmount = 1073 * 10 ** 6 - newTokenAmount
        let maxAmount = 1000000000 * maxWallet / 100;

        if (tokenAmount > maxAmount) {
          setCreating(false)
          toast.error(
            "You can't purchase more than " + ' ' + maxAmount.toLocaleString() + ' ' + " tokens"
          )
          return false;
        }
      }
      if (logoFile && bannerFile) {
        let create
        create = await writeContract({
          address: getFactoryAddress(chainId),
          abi: ChadFactoryAbi,
          functionName: 'createHotLaunch',
          value: web3Clients[chainId].utils.toWei(String(feeAmount + Number(depositAmount) + featuredAmount), 'ether'),
          args: [
            [
              tokenName,
              tokenSymbol,
              tokenDescription,
              website,
              twitter,
              telegram,
              discord
            ],
            maxWallet.toString(),
            routerAddr,
            featureOption.toString()
          ],
          chainId: chainId
        })
        await waitForTransaction({
          hash: create.hash
        })
        let funAddresses;
        funAddresses = await readContract({
          address: getFactoryAddress(chainId),
          abi: ChadFactoryAbi,
          functionName: 'getAllAddresses',
          chainId: chainId
        })
        let presaleAddress
        if (funAddresses)
          presaleAddress = funAddresses[funAddresses.length - 1];
        let logoUrl
        let bannerUrl
        if (logoFile) {
          const formData = new FormData()

          formData.append('file', logoFile, presaleAddress)
          fetch(imageUploadUrl + 'api/logoUploads', {
            method: 'POST',
            body: formData
          })
            .then(async res => {
              logoUrl = await res.json()
              logoUrl = logoUrl.fileInfo.filename
              if (bannerFile) {
                const formData = new FormData()
                formData.append('file', bannerFile, presaleAddress)
                fetch(imageUploadUrl + 'api/bannerUploads', {
                  method: 'POST',
                  body: formData
                })
                  .then(async res => {
                    bannerUrl = await res.json()
                    bannerUrl = bannerUrl.fileInfo.filename
                    toast.success(
                      `Successfully ${tokenName} HotLaunch created`
                    )
                    const link = `/buy/?chain=${chainId}&address=${presaleAddress}`
                    window.location.href = link
                  })
                  .catch(error => {
                    setCreating(false)
                    console.error('Error:', error)
                  })
              }
            })
            .catch(error => {
              setCreating(false)
              console.error('Error:', error)
            })
        }
        setCreating(false)
      } else {
        setCreating(false)
        toast.error(
          'please upload correct image file'
        )
      }
    } catch (err) {
      setCreating(false)
      console.error(err)
      toast.error(
        'There is a problem with your HotLaunch create. Please try again later'
      )
    }
  }

  const [, setImageLogoFile] = useState(null)

  const handleImageLogoChange = file => {
    setImageLogoFile(file)
  }

  const [, setImageBannerFile] = useState(null)

  const handleImageBannerChange = file => {
    setImageBannerFile(file)
  }

  const LogoImageUpload = ({ onChange, className, style }) => {
    const handleLogoImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = e.target.files![0]
      if (selectedFile.size > 1048576) {
        toast.error(
          "Logo size can't be greater than 1MB."
        )
        return;
      }
      setLogoFile(selectedFile)
      setLogoPreview(URL.createObjectURL(selectedFile))
      onChange(selectedFile)
    }
    const onButtonClick = () => {
      if (logoFileInput.current) {
        logoFileInput.current.click()
      }
    }
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <input
          type="file"
          ref={logoFileInput}
          accept="image/*"
          onChange={handleLogoImageChange}
          style={{ display: 'none' }}
        />
        <LogoUploadBox
          handleClick={onButtonClick}
          className={className}
          style={style}
        />
      </div>
    )
  }

  const BannerImageUpload = ({ onChange, className, style }) => {
    const handleBannerImageChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const selectedFile = e.target.files![0]
      if (selectedFile.size > 1048576) {
        toast.error(
          "Banner size can't be greater than 1MB."
        )
        return;
      }
      setBannerFile(selectedFile)
      setBannerPreview(URL.createObjectURL(selectedFile))
      onChange(selectedFile)
    }
    const onButtonClick = () => {
      if (bannerFileInput.current) {
        bannerFileInput.current.click()
      }
    }
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <input
          type="file"
          ref={bannerFileInput}
          accept="image/*"
          onChange={handleBannerImageChange}
          style={{ display: 'none' }}
        />
        <BannerUploadBox
          handleClick={onButtonClick}
          className={className}
          style={style}
        />
      </div>
    )
  }
  const setMaxWalletAmount = value => {
    setMaxWallet(value)
  }

  const changeChain = value => {
    setChainId(value)
    const routers = getRouterAddress(value)
    setRouters(routers)
  }

  const setRouterAddress = value => {
    setRouter(value)
  }

  return (
    <div>
      <div className="GlobalContainer">
        {
          <div style={{ zIndex: 1 }}>
            <TopBar />
            <div className="max-w-7xl m-auto pt-36 pb-24 px-4 sm:py-10">
              <section className="lg:mx-auto pt-8 lg:py-[30px] w-full min-w-0">
                <section>
                  <div className='flex flex-col md:flex-row gap-6'>
                    <div className="flex flex-col justify-center items-center gap-6 grow">
                      <h1 className="flex text-[#fff] w-full text-[20px] font-bold">General Information</h1>
                      <div className='flex flex-col gap-6 w-full'>
                        <div className='flex flex-col md:flex-row gap-6 w-full'>
                          <section className="flex flex-col w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="LpBalance">
                              <p className="text-[12px] text-[#f600ff]">
                                Name<span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <Input
                              placeholder="Enter Name"
                              label=""
                              type="text"
                              changeValue={setTokenName}
                              value={tokenName}
                            />
                          </section>

                          <section className="flex flex-col w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="LpBalance">
                              <p className="text-[12px] text-[#f600ff]">
                                Symbol<span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <Input
                              placeholder="Enter Symbol"
                              label=""
                              type="text"
                              changeValue={setTokenSymbol}
                              value={tokenSymbol}
                            />
                          </section>
                        </div>

                        <section className="flex flex-col w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                          <p className="text-[12px] text-[#f600ff]" style={{ order: '0' }}>
                            Description (Max 1000 characters)
                            <span style={{ color: 'red' }}>*</span>
                          </p>
                          <TextArea
                            rows={4}
                            placeholder="Enter Token Description"
                            changeValue={setTokenDescription}
                            value={tokenDescription}
                          />
                        </section>
                        <div className="flex flex-col md:flex-row gap-7 w-full">
                          <section className="flex flex-col w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="LpBalance">
                              <p className="text-[12px] text-[#f600ff] flex items-center">
                                Upload Logo
                                <span className='flex' style={{ color: 'red' }}>
                                  *
                                </span>
                                <a className='flex pl-12' id="my-anchor-element">
                                  <Tooltip
                                    anchorSelect="#my-anchor-element"
                                    className='w-64 md:w-80 lg:w-96 max-w-sm'
                                    content="Please upload only images in .png, .jpg. The ideal size for uploads is 256x256 pixels for optimal quality and fit."
                                  />
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="white"
                                    strokeWidth={2}
                                    className="h-5 w-5 cursor-pointer text-blue-gray-500"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                    />
                                  </svg>
                                </a>
                              </p>
                            </div>
                            <LogoImageUpload
                              onChange={handleImageLogoChange}
                              className={'cursor-pointer'}
                              style={undefined}
                            />
                          </section>

                          <section className="flex flex-col w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="LpBalance">
                              <p className="text-[12px] text-[#f600ff] flex items-center">
                                Upload Banner
                                <span className='flex' style={{ color: 'red' }}>
                                  *
                                  <a className='flex pl-8 text-[#d3d3d3]' id="banner-anchor-element">
                                    <Tooltip
                                      anchorSelect="#banner-anchor-element"
                                      className='w-64 md:w-80 lg:w-96 max-w-sm'
                                      content="Please upload only images in .png, .jpg. The ideal size for uploads is 1200x600 pixels for optimal quality and fit."
                                    />
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      className="h-5 w-5 cursor-pointer text-blue-gray-500"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                      />
                                    </svg>
                                  </a>
                                </span>
                              </p>
                            </div>
                            <BannerImageUpload
                              onChange={handleImageBannerChange}
                              className={'cursor-pointer'}
                              style={undefined}
                            />
                          </section>
                        </div>

                        <div className="text-[#fff] w-full text-[20px] font-bold">
                          Social links
                        </div>
                        <div className='flex flex-col md:flex-row gap-6'>

                          <section className="flex flex-col w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="LpBalance">
                              <p className="text-[12px] text-[#f600ff]">Telegram</p>
                            </div>
                            <Input
                              placeholder="Telegram Link"
                              label=""
                              type="text"
                              changeValue={setTelegram}
                              value={telegram}
                            />
                          </section>
                          <section className="flex flex-col w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="LpBalance">
                              <p className="text-[12px] text-[#f600ff]">Twitter</p>
                            </div>
                            <Input
                              placeholder="X Link"
                              label=""
                              type="text"
                              changeValue={setTwitter}
                              value={twitter}
                            />
                          </section>
                          <section className="flex flex-col w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="LpBalance">
                              <p className="text-[12px] text-[#f600ff]">Website</p>
                            </div>
                            <Input
                              placeholder="Website"
                              label=""
                              type="text"
                              changeValue={setWebsite}
                              value={website}
                            />
                          </section>
                        </div>

                        <div className="text-[#fff] w-full text-[20px] font-bold">
                          DEX
                        </div>
                        <div className='flex flex-col md:flex-row gap-6 w-full'>
                          <section className="flex flex-col gap-2 w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="LpBalance">
                              <p className="text-[12px] text-[#f600ff]">Network</p>
                            </div>
                            <div className="fairlaunch-allocation-buttons-container">
                              {supportedChainIds.map(id => (
                                <button
                                  key={id}
                                  className={`${chainId === id ? 'opacity-100' : 'opacity-30'}`}
                                  onClick={() => {
                                    changeChain(id)
                                  }}
                                >
                                  <img src={chainLogos[id]} className='w-8' />
                                </button>
                              ))}
                            </div>
                          </section>
                          <section className="flex flex-col gap-2 w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="LpBalance">
                              <p className="text-[12px] text-[#f600ff]">Dex</p>
                            </div>
                            <div className="fairlaunch-allocation-buttons-container">
                              {Object.entries(routers).map(([key, value]) => (<button
                                className="fairlaunch-allocation-button"
                                onClick={() => {
                                  setRouterAddress(value)
                                }}
                                style={
                                  routerAddr === value
                                    ? {}
                                    : { background: 'transparent', color: '#f600ff', border: 'solid #f600ff 1px' }
                                }
                              >
                                {key}
                              </button>))}
                            </div>
                          </section>
                        </div>

                        <div className="text-[#fff] w-full text-[20px] font-bold">
                          Features
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 w-full'>
                          <section className="flex flex-col gap-4 w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="LpBalance">
                              <p className="text-[12px] text-[#f600ff]">
                                Max Wallet (%)
                                <span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <div className="fairlaunch-allocation-buttons-container">
                                  <button
                                    className="fairlaunch-allocation-button"
                                    onClick={() => {
                                      setMaxWalletAmount(1)
                                    }}
                                    style={
                                      maxWallet === 1
                                        ? {}
                                        : { background: 'transparent', color: '#f600ff', border: 'solid #f600ff 1px' }
                                    }
                                  >
                                    1%
                                  </button>
                                  <button
                                    className="fairlaunch-allocation-button"
                                    onClick={() => {
                                      setMaxWalletAmount(2)
                                    }}
                                    style={
                                      maxWallet === 2
                                        ? {}
                                        : { background: 'transparent', color: '#f600ff', border: 'solid #f600ff 1px' }
                                    }
                                  >
                                    2%
                                  </button>
                                </div>
                              </section>
                            </section>
                          </section>
                          <section className="flex flex-col gap-4 w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="LpBalance">
                              <p className="text-[12px] text-[#f600ff] flex items-center">
                                Bundle Amount ({coinNames[chainId]})
                                <a className='flex pl-4' id="snipe-anchor-element">
                                  <Tooltip
                                    anchorSelect="#snipe-anchor-element"
                                    className='w-64 md:w-80 lg:w-96 max-w-sm'
                                    content="This is where you input how much you want to buy at launch. Bundling means automatically buying tokens with your dev wallet in the same txhash as the token deployment. This feature guarantees you will be the first buyer of your own token."
                                  />
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="white"
                                    strokeWidth={2}
                                    className="h-5 w-5 cursor-pointer text-blue-gray-500"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                    />
                                  </svg>
                                </a>
                              </p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader w-full">
                                <Input
                                  placeholder="Enter Snipe Amount"
                                  label=""
                                  type="number"
                                  changeValue={setDepositAmount}
                                  value={depositAmount}
                                />
                              </section>
                            </section>
                          </section>
                          <section className="flex flex-col gap-4 w-full bg-[#7e0087] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                            <div className="flex items-center text-[12px] text-[#f600ff]">
                              Featured Listing Option
                              <a className='flex pl-4' id="feature-anchor-element">
                                <Tooltip
                                  anchorSelect="#feature-anchor-element"
                                  className='w-64 md:w-80 lg:w-96 max-w-sm'
                                  content={`This is where you can select featured listing option. ${featureAmounts[chainId][1]}${coinNames[chainId]}: 1 week, ${featureAmounts[chainId][2]}${coinNames[chainId]}: 2 weeks, ${featureAmounts[chainId][3]}${coinNames[chainId]}: 1 month`}
                                />
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="white"
                                  strokeWidth={2}
                                  className="h-5 w-5 cursor-pointer text-blue-gray-500"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                  />
                                </svg>
                              </a>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <div className="fairlaunch-allocation-buttons-container">
                                  <button
                                    className="fairlaunch-allocation-button"
                                    onClick={() => {
                                      setFeatureOption(0)
                                    }}
                                    style={
                                      featureOption === 0
                                        ? {}
                                        : { background: 'transparent', color: '#f600ff', border: 'solid #f600ff 1px' }
                                    }
                                  >
                                    Basic
                                  </button>
                                  <button
                                    className="fairlaunch-allocation-button"
                                    onClick={() => {
                                      setFeatureOption(1)
                                    }}
                                    style={
                                      featureOption === 1
                                        ? {}
                                        : { background: 'transparent', color: '#f600ff', border: 'solid #f600ff 1px' }
                                    }
                                  >
                                    1 week
                                  </button>
                                  <button
                                    className="fairlaunch-allocation-button"
                                    onClick={() => {
                                      setFeatureOption(2)
                                    }}
                                    style={
                                      featureOption === 2
                                        ? {}
                                        : { background: 'transparent', color: '#f600ff', border: 'solid #f600ff 1px' }
                                    }
                                  >
                                    2 weeks
                                  </button>
                                  <button
                                    className="fairlaunch-allocation-button"
                                    onClick={() => {
                                      setFeatureOption(3)
                                    }}
                                    style={
                                      featureOption === 3
                                        ? {}
                                        : { background: 'transparent', color: '#f600ff', border: 'solid #f600ff 1px' }
                                    }
                                  >
                                    1 month
                                  </button>
                                </div>
                              </section>
                            </section>
                          </section>
                        </div>

                      </div>
                    </div>
                    <aside className=" w-full md:w-[360px]">
                      <div
                        className="group flex-grow flex flex-col relative min-w-[350px] w-full md:max-w-[411.95px] basis-0  bg-[#7e0087] rounded-[32px] overflow-hidden">
                        <div className="relative w-full aspect-[3/1] overflow-hidden z-40">
                          <img alt="banner" loading="lazy"
                            decoding="async" data-nimg="fill"
                            className="w-full h-full object-cover group-hover:scale-[1.035] transition-all ease-in-out duration-500"
                            src={bannerPreview ? bannerPreview : sampleBanner} />
                        </div>
                        <div
                          className="flex flex-col gap-[16px] w-full p-6 rounded-[32px] mt-[-42px] z-40 border border-[#8c00f5] bg-[#7e0087] transition-all ease-in-out duration-150">
                          <div className="token-header flex justify-between gap-4">
                            <div className="token-profile flex gap-4 flex-grow">
                              <img alt="banner" loading="lazy" width="50" height="50"
                                decoding="async" data-nimg="1"
                                className="w-[50px] h-[50px] flex-shrink-0 rounded-full overflow-hidden"
                                src={logoPreview ? logoPreview : sampleLogo} />
                              <div className="flex flex-col gap-0"><span
                                className="text-white font-bold text-[20px] whitespace-nowrap overflow-hidden text-ellipsis">{tokenName}</span><span
                                  className="font-semibold text-[#919191] text-[16px] uppercase">{tokenSymbol}</span></div>
                            </div>
                            <img src={chainLogos[chainId]} className='w-8' />
                          </div>
                          <span className='font-light text-white text-[14px]'>{tokenDescription.length > 120 ? tokenDescription.slice(0, 120) + '...' : tokenDescription}</span>
                          <div className="socials flex gap-[16px]">
                            <a href={website}>
                              <img alt="globe" loading="lazy" width="24" height="24"
                                decoding="async" data-nimg="1" className="" src={web} />
                            </a>
                            <a href={twitter}>
                              <img alt="x" loading="lazy" width="24" height="24"
                                decoding="async" data-nimg="1" className="" src={x} />
                            </a>
                            <a href={telegram}>
                              <img alt="telegram" loading="lazy" width="24"
                                height="24" decoding="async" data-nimg="1" className=""
                                src={tlg} />
                            </a>
                          </div>
                          <>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                width: '100%'
                              }}
                            >
                              {
                                isConnected ?
                                  chain?.id === chainId ?
                                    (
                                      <button
                                        disabled={
                                          tokenName === '' ||
                                          tokenSymbol === '' ||
                                          tokenDescription === '' ||
                                          logoFile === null ||
                                          bannerFile === null ||
                                          feeAmounts[chainId] + Number(depositAmount) + featureAmounts[chainId][featureOption] > accountBalance
                                        }
                                        onClick={onBlackPumpCreate}
                                        className="CreateButton flex justify-center items-center"
                                      >
                                        {tokenName === '' ||
                                          tokenSymbol === '' ||
                                          tokenDescription === '' ||
                                          logoFile === null ||
                                          bannerFile === null
                                          ? 'Please Enter Details'
                                          :
                                          feeAmounts[chainId] + Number(depositAmount) + featureAmounts[chainId][featureOption] > accountBalance ?
                                            'Insufficient Balance'
                                            : creating === false
                                              ? 'Create'
                                              :
                                              <ClipLoader
                                                color={'#222'}
                                                loading={creating}
                                                size={30}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                              />}
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          className="CreateButton"
                                          type="submit"
                                          onClick={() => {
                                            switchNetwork?.(chainId)
                                          }}
                                        >
                                          Switch Network
                                        </button>
                                      </>
                                    ) : (
                                    <>
                                      <button
                                        className="CreateButton"
                                        type="submit"
                                        onClick={() => {
                                          onConnectWallet()
                                        }}
                                      >
                                        Connect
                                        <span className="navWallet"> Wallet</span>
                                      </button>
                                    </>
                                  )
                              }
                            </div>
                          </>
                        </div>
                      </div>
                    </aside>
                  </div>
                </section>
              </section>
            </div>
          </div>
        }
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default App
